import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"


const query = graphql`
  {
    allStrapiBlog(sort: {fields: published_at, order: DESC}
      filter: {category: {slug: {in: ["cpanel", "domenii", "general"]}}}
      ){
      nodes  {
          id
          title
          published_at(formatString: "D MMMM, YYYY", locale: "ro")
          image {
            childImageSharp {
               fixed {
                  src
               }
            }
          }
          slug
          category {
            title
            slug
          }
        }
      }
  }
`

const BlogSidebar = () => {
  const data = useStaticQuery(query)
  const blogPosts = data.allStrapiBlog.nodes


  const cPanelPosts = []
  const domainsPosts = []
  const generalPosts = []

  const recentPosts = []


  for (let post of blogPosts) {
    if (post.category.slug === "cpanel") {
      cPanelPosts.push(post)
    } else if (post.category.slug === "general") {
      generalPosts.push(post)
    } else if (post.category.slug === "domenii") {
      domainsPosts.push(post)
    }
  }

  for (let i = 0; i < 3; i++) {
    recentPosts.push(blogPosts[i])
  }


  return (
    <div className="widget-area">
      <div className="widget widget_search">
        <h3 className="widget-title">Caută articol</h3>

        <form className="search-form">
          <label>
            <span className="screen-reader-text">Search for:</span>
            <input type="search" className="search-field" placeholder="Caută ..." />
          </label>
          <button type="submit">
            <i className="bx bx-search-alt" />
          </button>
        </form>
      </div>

      <div className="widget widget_tracer_posts_thumb">
        <h3 className="widget-title">Recente</h3>
        {
          recentPosts.map(data => {
            return (
              <article className="item" key={data.id}>
                <Link to={"/" + data.slug + "/"} className="thumb">
                  <img className="fullimage cover" src={data.image.childImageSharp.fixed.src} alt="Blog Tumbs" />
                </Link>
                <div className="info">
                  <span>{data.published_at}</span>
                  <h4 className="title usmall">
                    <Link to={"/" + data.slug + "/"}>
                      {data.title.substring(0,45)}...
                    </Link>
                  </h4>
                </div>
                <div className="clear" />
              </article>
            )
          })
        }
      </div>

      <div className="widget widget_categories">
        <h3 className="widget-title">Categorii</h3>

        <ul>
          <li>
            <Link to="/category/cpanel/">
              cPanel <span className="post-count">({cPanelPosts.length})</span>
            </Link>
          </li>
          <li>
            <Link to="/category/general/">
              General <span className="post-count">({generalPosts.length})</span>
            </Link>
          </li>
          <li>
            <Link to="/category/domenii/">
              Domenii <span className="post-count">({domainsPosts.length})</span>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  )
}


export default BlogSidebar
